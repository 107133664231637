import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import axios from 'axios'
import { createStore } from 'vuex'
import authStore from '@/store/auth.module'
import VueAxios from 'vue-axios/dist/vue-axios.common.min'
import Toast from 'vue-toastification'
import VueDatePicker from '@vuepic/vue-datepicker'
import Multiselect from '@vueform/multiselect'
import { QuillEditor } from '@vueup/vue-quill'
import NumberInput from '@/components/vue-number-input/vue-number-input.vue'
import Toggle from '@vueform/toggle'
import MainHeader from '@/components/partial/MainHeader.vue'
import MainFooter from '@/components/partial/MainFooter.vue'
import '@vueform/multiselect/themes/default.css'
import 'vue-toastification/dist/index.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import 'quill-mention/dist/quill.mention.css'
import '@vuepic/vue-datepicker/dist/main.css'
import '@vueform/toggle/themes/default.css'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

Multiselect.props.noResultsText.default = 'Записи не найдены'
Multiselect.props.noOptionsText.default = 'Записи не найдены'

VueDatePicker.props.format.default = 'dd.MM.yyyy'
VueDatePicker.props.locale.default = 'ru-RU'
VueDatePicker.props.cancelText.default = 'Отмена'
VueDatePicker.props.selectText.default = 'Выбрать'
VueDatePicker.props.inputClassName.default = 'c-input c-input-data c-filter__input'
VueDatePicker.props.enableTimePicker.default = false

const store = createStore({
  modules: {
    auth: authStore,
  }
})

const app = createApp(App)
app.use(router)
app.use(VueAxios, axios)
app.use(store)
app.use(Toast)
app.use(VueTelInput)
app.component('QuillEditor', QuillEditor)
app.component('MainHeader', MainHeader)
app.component('MainFooter', MainFooter)
app.component('VueDatePicker', VueDatePicker)
app.config.globalProperties.$filters = {
  numberFormat (val, zn) {
    if (val === null) {
      return ''
    }
    if (zn > 0) {
      val = parseFloat(val).toFixed(zn)
    }
    return `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')
  },
  yn (val) {
    if (val) {
      return 'Да'
    } else {
      return 'Нет'
    }
  }
}
// eslint-disable-next-line vue/multi-word-component-names
app.component('Multiselect', Multiselect)
app.component('Toggle', Toggle)
app.component('NumberInput', NumberInput)
app.provide('axios', app.config.globalProperties.axios)
app.mount('#app')
