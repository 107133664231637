<script setup>

import MainFooter from '@/components/partial/MainFooter.vue'
import { computed, ref } from 'vue'
import {Dictionary, Funds, Regions} from '@/common/api.service'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const item = ref({})
const supportedIndustries = ref([])
const targetedUses = ref([])
const fund = ref({})
const region = ref({})

await Regions.item(route.params.id)
    .then(({ data }) => {
      region.value = data
    }).catch(() => {
    })
await Funds.item(region.value.fund.id)
    .then(({ data }) => {
      fund.value = data
    }).catch(() => {
    })
await Dictionary.supportedIndustries()
    .then(({ data }) => {
      supportedIndustries.value = data
    }).catch(() => {
    })

await Dictionary.targetedUses()
    .then(({ data }) => {
      targetedUses.value = data
    }).catch(() => {
    })

const supportedIndustriesList = computed(() => {
  let r = []
  supportedIndustries.value.forEach(function (f) {
    r.push({ value: f.value, label: `${f.title}` })
  })

  return r
})

const targetedUsesList = computed(() => {
  let r = []
  targetedUses.value.forEach(function (f) {
    r.push({ value: f.value, label: `${f.title}` })
  })

  return r
})

async function saveOwnProgram () {
  await Funds.createOwnPrograms(route.params.fund, item.value).then(async () => {
    await router.push({ name: 'CardRegions', params: { id: route.params.id } })
  })
}

</script>

<template>
  <div class="wrapper">
    <MainHeader></MainHeader>
    <main class="page page--js">
      <div class="breadcrumbs-container">
        <div class="container">
          <div class="breadcrumbs-wrapper">
            <ul class="breadcrumbs">
              <li>
                <router-link
                    :to="{name: 'ListRegions'}">Модуль регионов
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{name: 'ListRegions'}">Список регионов
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{name: 'CardRegions', params: { fund: fund.id}}">
                  {{ fund.region.title }}
                </router-link>
              </li>
              <li>Добавление самостоятельной программы</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="content__wrapper">
            <div class="content__contract">
              <div class="content__title content__title--contract">
                <h1 class="title title-h1--contract">Добавление самостоятельной программы</h1>
              </div>
              <form action="#" class="contract">
                <div class="contract__row">
                  <div class="contract__item">
                    <label for="independent-program-name" class="title-field">Название
                      самостоятельной программы*
                      <input class="field" type="text" v-model="item.title"
                             placeholder="Введите название программы">
                    </label>
                    <label for="description-program" class="title-field">Описание программы*
                      <textarea class="field field--textarea" type="text"
                                v-model="item.description"
                                placeholder="Введите описание программы"></textarea>
                    </label>
                    <label for="industry" class="title-field">Поддерживаемые отрасли*
                      <Multiselect
                          placeholder="Введите название отрасли"
                          class="field field--select" style="height: auto"
                          v-model="item.supportedIndustries"
                          mode="tags"
                          :searchable="true"
                          :aria-expanded="true"
                          :options="supportedIndustriesList"
                      />
                    </label>
                    <label for="direction" class="title-field">Направление целевого использования*
                      <Multiselect
                          placeholder="Введите название направления"
                          class="field field--select" style="height: auto"
                          v-model="item.targetedUses"
                          mode="tags"
                          :searchable="true"
                          :aria-expanded="true"
                          :options="targetedUsesList"
                      />
                    </label>
                    <label for="gisp-card-link" class="title-field">Ссылка на карточку ГИСП
                      <input class="field" type="text" v-model="item.gispCardUrl"
                             placeholder="Введите URL адрес">
                    </label>
                    <label for="rfrp-card-link" class="title-field">Ссылка на сайт РФРП
                      <input class="field" type="text" v-model="item.rfrpCardUrl"
                             placeholder="Введите URL адрес">
                    </label>
                  </div>
                  <div class="contract__item">
                    <label for="loan-term" class="title-field">Срок займа, диапазон лет*
                      <input class="field" type="text" v-model="item.loanDuration"
                             placeholder="Введите диапазон срока займа">
                    </label>
                    <div class="interval-fields">
                      <label for="loan-term-min" class="title-field">Сумма займа, млн руб.*
                        <input class="field" type="text" v-model="item.loanSumMin"
                               placeholder="Введите минимальную сумму">
                      </label>
                      <span></span>
                      <label for="loan-term-max" class="title-field">
                        <input class="field" type="text" v-model="item.loanSumMax"
                               placeholder="Введите максимальную сумму">
                      </label>
                    </div>
                    <label for="loan-rate" class="title-field">Ставка, %*
                      <input class="field" type="text" v-model="item.rate"
                             placeholder="Введите ставку в процентах">
                    </label>
                    <label for="loan-co-financing" class="title-field">Софинансирование, %*
                      <input class="field" type="text" v-model="item.cofinanceRate"
                             placeholder="Введите условия софинансирования">
                    </label>
                    <label for="total-budget" class="title-field">Общий бюджет заявки, млн руб.
                      <input class="field" type="text" v-model="item.commonBudget"
                             placeholder="Введите общий бюджет заявки">
                    </label>
                  </div>
                </div>
                <div class="buttons-block buttons-block--right">
                  <router-link :to="{ name: 'CardRegions', params: { id: region.id } }" class="button button--clear">
                    Отмена изменений
                  </router-link>
                  <button type="button" class="button" @click="saveOwnProgram">сохранить</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped>

</style>