<script setup>
import MainFooter from "@/components/partial/MainFooter.vue";
import { ref, defineProps, computed } from 'vue';
import { Regions, Dictionary, Regulations } from "@/common/api.service";
import { useRouter } from "vue-router";

const props = defineProps({
    selectedRegion: {
        type: String,
        required: true
    }
});

const router = useRouter();
const regions = ref([]);
const statutes = ref([]);
const typesNpa = ref([]);
const regionSelected = ref(props.selectedRegion);
const npaData = ref({
    regulationsType: null,
    regulationsStatus: null,
    title: "",
    federalRate: null,
    regionalRate: null,
    verificationDate: "",
    comment: ""
});

await Regions.list()
    .then(({ data }) => {
        regions.value = data;
    }).catch(() => { });

await Dictionary.regulationsStatuses()
    .then(({ data }) => {
        statutes.value = data;
    }).catch(() => { });

await Dictionary.regulationsTypes()
    .then(({ data }) => {
        typesNpa.value = data;
    }).catch(() => { });

const regionList = computed(() => {
    return regions.value.map(region => ({ value: region.id, label: region.title }));
});

const statutesList = computed(() => {
    return statutes.value.map(status => ({ value: status.value, label: status.title }));
});

const typesNpaList = computed(() => {
    return typesNpa.value.map(typeNpa => ({ value: typeNpa.value, label: typeNpa.title }));
});

async function addNewNpa() {
    await Regulations.createNpa(regionSelected.value, npaData.value)
        .then(() => {
            return router.push({ name: 'ListNpa', params: { region: regionSelected.value } });
        })
        .catch(() => {
        });
}

async function closeAddNewNpa() {
    if (regionSelected.value) {
        router.push({ name: 'ListNpa', params: { region: regionSelected.value } });
    }
}
</script>


<template>
    <div class="wrapper">
        <MainHeader></MainHeader>
        <main class="page page--js">
            <div class="breadcrumbs-container">
                <div class="container">
                    <div class="breadcrumbs-wrapper">
                        <ul class="breadcrumbs">
                            <li>
                                <router-link :to="{ name: 'ListRegions' }">Модуль регионов</router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'ListNpa', params: { region: regionSelected } }">
                                    Реестр НПА по СПИК
                                </router-link>
                            </li>
                            <li>Добавление нового НПА</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="container">
                    <div class="content__wrapper">
                        <div class="content__contract">
                            <div class="content__title content__title--contract">
                                <h1 class="title title-h1--contract">Добавление нового НПА</h1>
                            </div>
                            <form action="#" class="contract" @submit.prevent="addNewNpa">
                                <div class="contract__row">
                                    <div class="contract__item">
                                        <label for="region" class="title-field hidden">Регион*
                                        <Multiselect class="field field--select margin-0" name="region"
                                            v-model="regionSelected" mode="single" :options="regionList"
                                            :show-no-results="false" :searchable="true"
                                            placeholder="Наименование региона" :no-options-text="'Укажите регион'"
                                            disabled />
                                        </label>

                                        <label for="type-NPA" class="title-field">Тип*
                                        <Multiselect class="field field--select margin-0" name="type-NPA"
                                            v-model="npaData.regulationsType" mode="single" :options="typesNpaList"
                                            :show-no-results="false" :searchable="true"
                                            placeholder="Выберите из списка" :no-options-text="'Укажите тип'" />
                                        </label>

                                        <label for="status-NPA" class="title-field">Статус*
                                        <Multiselect class="field field--select margin-0" name="status-NPA"
                                            v-model="npaData.regulationsStatus" mode="single"
                                            :options="statutesList" :show-no-results="false" :searchable="true"
                                            placeholder="Статус НПА" :no-options-text="'Укажите статус'" />
                                    </label>
                                        <label for="NPA-name" class="title-field">Название*
                                        <textarea class="field field--textarea" type="text" name="NPA-name"
                                            v-model="npaData.title" placeholder="Введите название НПА" maxlength="255"></textarea>
                                    </label>
                                        <label for="tax-rate" class="title-field">Размер налоговой ставки для федеральных СПИК, %
                                            <input class="field" type="text" name="tax-rate" v-model="npaData.federalRate" placeholder="Введите размер налоговой ставки в процентах" maxlength="7" pattern="^\d+(\.\d{0,2})?$" inputmode="decimal" oninput="this.value = this.value.replace(/[^0-9.]/g, '')
                                        .replace(/(\..*?)\..*/g, '$1')
                                        .replace(/(\.\d{2})\d+/, '$1');" >
                                    </label>
                                        <label for="tax-rate-regional" class="title-field">Размер налоговой ставки для региональных СПИК, %
                                        <input class="field" type="text" name="tax-rate-regional"
                                            v-model="npaData.regionalRate"
                                            placeholder="Введите размер налоговой ставки в процентах"
                                            maxlength="7" pattern="^\d+(\.\d{0,2})?$" inputmode="decimal" oninput="this.value = this.value.replace(/[^0-9.]/g, '')
                                        .replace(/(\..*?)\..*/g, '$1')
                                        .replace(/(\.\d{2})\d+/, '$1');" >
                                        </label>
                                    </div>
                                    <div class="contract__item">
                                        <label for="transfer-date" class="title-field">Дата проверки*
                                        <VueDatePicker name="transfer-date" placeholder="00.00.0000"
                                            model-type="yyyy-MM-dd" class="field field--calendar"
                                            v-model="npaData.verificationDate" />
                                        </label>

                                        <label for="comment-NPA" class="title-field">Комментарий
                                        <textarea class="field field--textarea" type="text" name="comment-NPA"
                                            v-model="npaData.comment" placeholder="Введите комментарий" maxlength="2000"></textarea>
                                        </label>
                                    </div>
                                </div>
                                <div class="buttons-block buttons-block--right">
                                    <button type="reset" class="button button--clear" @click="closeAddNewNpa">Отмена изменений</button>
                                    <button type="submit" class="button">Сохранить</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <MainFooter></MainFooter>
    </div>
</template>
